import ModuleButtonList from './modules/ModuleButtonList';

import './styles.css';

export default function Hero() {
  return (
    <div className='features'>
      <div className='wrapper'>
        <ModuleButtonList />
      </div>
    </div>
  );
}
