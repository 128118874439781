import React from 'react';

import Onboarding from '@components/UI/Views/Home/Onboarding';
import Features from '@components/UI/Views/Home/Features';
import HomeDemo from '@components/UI/Views/Home/Demo';
// import Clients from '@components/UI/Views/Home/Clients/index';

// Styles
import './index.css';

let Index = () => {
  return (
    <div id='index'>
      <Onboarding />
      <Features />
      <HomeDemo />
      {/* <Clients /> */}
    </div>
  );
};

export default Index;
