import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//* Data
import packageJSON from '../../../../package.json';

import './Footer.css';

const Footer = () => {
  const { t, i18n } = useTranslation();
  let _language = i18n.getCurrentLanguage();

  const handleLangChange = (lang) => {
    // const lang = evt.target.value;
    i18n.setCurrentLanguage(lang);
    i18n.changeLanguage(lang);
  };

  // TODO: Check Mobile/Desktop layout.
  // TODO: Change link to router ones.
  return (
    <footer className='app-footer'>
      <div className='wrapper'>
        <div className='container '>
          <div className='node'>
            <div className='f-logo-ov'></div>
            <div className='inner'>
              <nav className='footer-menu'>
                <ul className='menu inline-menu no-edge-margins'>
                  <li>
                    <Link to='/doc/api'>{t('Footer.Documentation')}</Link>
                  </li>
                  <li>
                    <Link to='/info'>{t('Footer.Faq')}</Link>
                  </li>
                </ul>
              </nav>
              <div className='copyright content'>
                <span className='note'>
                  <span>
                    {t('Footer.Description')}
                    <br />
                  </span>
                  {t('Footer.Copyright')}
                </span>
              </div>
            </div>
          </div>
          <div className='node'>
            <div className='inner right'>
              <ul className='social-menu menu inline-menu'>
                <li>
                  <div onClick={() => handleLangChange('rs')} className={['langSelect', `${_language === 'rs' ? 'active' : ''}`].join(' ')}>
                    RS
                  </div>
                  <div onClick={() => handleLangChange('en')} className={['langSelect', `${_language === 'en' ? 'active' : ''}`].join(' ')}>
                    EN
                  </div>
                </li>
                <li>
                  <Link to='/contact' className='hover'>
                    {t('Footer.Contact')}
                  </Link>
                </li>
              </ul>
              <div className='credit right content'>
                <span className='version app-version'>beta {packageJSON.version}</span>
                <a className='credit-logo' href={'https://mainloop.rs'} target='_blank' rel='noopener noreferrer'>
                  mainloop
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
