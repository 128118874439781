import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import PageHeader from '@components/UI/PageHeader';
import StyckyNav from '@components/Shared/Navigation/StickyNavbar';

import Informations from '@components/UI/Informations';
import FAQ from '@components/UI/FAQ';

// Style
import './style.css';

let InfoPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='view info' id='info'>
        <PageHeader name={t('FAQ.Title')} subname={t('FAQ.SubName')} description={t('FAQ.Description')} />
        <div className='has-header'>
          <div className='wrapper full'>
            <div className='container'>
              <Informations docApi={true} wp={true} />
              <FAQ />
            </div>
          </div>
        </div>
      </div>
      <StyckyNav />
    </>
  );
};

export default InfoPage;
