import React from 'react';

// import { Link } from 'react-router-dom';

import Button from '@components/UI/Button';
import { ReactComponent as Info } from '../../../media/inc/example/info.svg';

import './style.css';

let Tip = (props) => {
  return (
    <div className='group full tip'>
      <div className='bind'>
        <div className='col'>
          <div className='icon'>
            <Info />
          </div>
          <div className='text'>
            <div className='name'>{props.title}</div>
            <div className='description'>
              <p>{props.description}</p>
            </div>
          </div>
          <a href={'https://teitqw.am.files.1drv.com/y4mRStMV-wsr03sBAg5BhVTmq7gffoumQGefQzSmNrAp0Q-drxZcNpqDOuMu67eaFBIKYqmcArZ7ezAR5khPFg2mr_Hu-mzZl723cAA4SgNg_1P9XamZCY9kAwwNHyRjKFO9ERQr_6Qu6qxyAUk_wIKwRFlsWPhmUAeV-JkHZEb1s9PwtxXQn2tsY0fjm7Mta2xCItnr9ax7SvAU3CC53Kb5g'} target='_blank' rel='noopener noreferrer'>
            <Button text={props.link.text} status />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Tip;
