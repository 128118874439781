import React from 'react';
import { Link } from 'react-router-dom';

// Images
import { ReactComponent as QR } from '../../../media/inc/qr.svg';
import { ReactComponent as Document } from '../../../media/inc/document.svg';
import { ReactComponent as Slip } from '../../../media/inc/slip.svg';

import './styles.css';

// TODO: Links to QR & Slip needs to call function to scroll down.
let Informations = (props) => {
  return (
    <div className='informations'>
      <div className='wrapper'>
        <div className='documentation'>
          <div className='methods'>
            {props.qr ? (
              <Link to='/doc/api'>
                <div className='op qr'>
                  <div className='select'>
                    <div className='image'>
                      <QR />
                    </div>
                    <div className='name'>QR Kod</div>
                  </div>
                </div>
              </Link>
            ) : null}
            {props.slip ? (
              <Link to='/doc/api'>
                <div className='op slip'>
                  <div className='select'>
                    <div className='image'>
                      <Slip />
                    </div>
                    <div className='name'>Uplatnica</div>
                  </div>
                </div>
              </Link>
            ) : null}
            {props.wp ? (
              <Link to='/doc/wordpress'>
                <div className='op slip'>
                  <div className='select button'>
                    <div className='image'>
                      <Slip />
                    </div>
                    <div className='name'>Wordpress</div>
                  </div>
                </div>
              </Link>
            ) : null}
            {props.docApi ? (
              <Link to='/doc/api'>
                <div className='op slip'>
                  <div className='select button'>
                    <div className='image'>
                      <Document />
                    </div>
                    <div className='name'>API Dokumentacija</div>
                  </div>
                </div>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informations;
