import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// CSS
import style from './NavLinks.module.css';

const NavLinks = () => {
  const { t } = useTranslation();

  return (
    <nav className={style.NavLinks}>
      <ul className={style.class}>
        {/* <li>
          <NavLink to='/demo'>{t('Nav.Demo')}</NavLink>
        </li> */}
        <li>
          <NavLink to='/info'>{t('Nav.InfoPage')}</NavLink>
        </li>
        <li>
          <NavLink to='/contact'>{t('Nav.Contact')}</NavLink>
        </li>
      </ul>
    </nav>
  );
};
export default NavLinks;
