import './styles.css';

import { AnnotationIcon, CodeIcon, DocumentIcon, ViewGridIcon } from '@heroicons/react/outline';

import { NavLink } from 'react-router-dom';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MobileTabs() {
  const location = useLocation();

  const { t, i18n } = useTranslation();

  return (
    <nav className='mobile-tabs'>
      <div className='node'>
        <NavLink to='/'>
          <ViewGridIcon className={`${location.pathname === '/' && 'active'}`} />
          <b>{t('Nav.Home')}</b>
        </NavLink>
        <NavLink to='/info'>
          <DocumentIcon className={`${location.pathname === '/info' && 'active'}`} />
          <b>{t('Nav.InfoPage')}</b>
        </NavLink>
        <NavLink to='/contact'>
          <AnnotationIcon className={`${location.pathname === '/contact' && 'active'}`} />
          <b>{t('Nav.Contact')}</b>
        </NavLink>
        <NavLink to='/doc/api'>
          <CodeIcon className={`${location.pathname === '/doc/api' && 'active'}`} />
          <b>API</b>
        </NavLink>
      </div>
    </nav>
  );
}

export default MobileTabs;
