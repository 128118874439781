import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ModuleButton({ title, image }) {
  const { t } = useTranslation();

  return (
    <Link to='/' className='feature-box'>
      <div className='image'>
        <img src={image} alt={title} />
      </div>
      <h5>{t(title)}</h5>
    </Link>
  );
}
