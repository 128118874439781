let localStorage = window.localStorage;

export let set = (key, value) => {
  value = JSON.stringify(value);

  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.log(e);
  }
};

export let del = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
  }
};

export let get = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.log(e);
  }
};

export let update = (key, value, unique, limit) => {
  let json = null;

  try {
    json = localStorage.getItem(key);
  } catch (e) {
    console.log(e);
  }

  if (!json) {
    set(key, [value]);
  } else {
    let parsed = JSON.parse(json);
    if (unique) {
      let index = parsed.findIndex((obj) => obj.membershipId === value.membershipId);
      if (index > -1) {
        parsed.splice(index, 1);
      }

      if (!limit) {
        limit = parsed.length + 1;
      }

      parsed = [value, ...parsed.slice(0, limit)];
    } else {
      if (!limit) {
        limit = parsed.length + 1;
      }

      parsed = [value, ...parsed.slice(0, limit)];
    }
    // console.log(json, parsed, "updating");
    set(key, parsed);
  }
};
