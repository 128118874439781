import ModuleButton from './ModuleButton';

export default function ModuleButtonList() {
  return (
    <div className='list'>
      <ul>
        <li>
          <ModuleButton title='Features.SecurePayment' image='/static/images/features/check_shield_outline.svg' />
        </li>
        <li>
          <ModuleButton title='Features.MobilePayment' image='/static/images/features/smartphone_outline.svg' />
        </li>
        <li>
          <ModuleButton title='Features.InstangPayment' image='/static/images/features/speedometer_start_outline.svg' />
        </li>
        <li>
          <ModuleButton title='Features.ZeroFee' image='/static/images/features/coins_outline.svg' />
        </li>
        <li>
          <ModuleButton title='Features.DedicatedAccount' image='/static/images/features/bank_outline.svg' />
        </li>
        <li>
          <ModuleButton title='Features.Integration' image='/static/images/features/download_cloud_outline.svg' />
        </li>
        <li>
          <ModuleButton title='Features.Wordpress' image='/static/images/features/wordpress.svg' />
        </li>
        <li>
          <ModuleButton title='Features.Magento' image='/static/images/features/magento.svg' />
        </li>
      </ul>
    </div>
  );
}
