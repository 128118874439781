import React from 'react';

// Data
import packageJSON from '../../../package.json';

// Components
import Spinner from '@components/UI/Spinner';
import { ReactComponent as LogoTexture } from '../../media/logo-texture-white.svg';

// Styles
import './style.css';

const Loading = (props) => {
  return (
    <div className='view' id='loading'>
      <div className='bg'>
        <div className='containment'>{/* <ObservedImage className='image' src='/static/images/extracts/flair/01E3-0000130C.png' /> */}</div>
      </div>
      <div className='logo-feature'>
        <div className='device'>
          <LogoTexture />
        </div>
      </div>
      <div className='text'>
        <div className='version'>MAINLOOP {packageJSON.version}</div>
        <div className='status'>
          <Spinner />
          <div>{props.status.code}</div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
