import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './NavLinks.module.css';

let StickyNavbar = () => {
  let history = useHistory();
  const { t } = useTranslation();
  return (
    <div className={styles.stickyNav}>
      <div className={styles.wrapper}>
        <button className={styles.button} onClick={() => history.goBack()}>
          {t('Nav.Back')}
        </button>
      </div>
    </div>
  );
};

export default StickyNavbar;
