import React from 'react';

import { Link } from 'react-router-dom';
import Button from '@components/UI/Button';

import { useTranslation } from 'react-i18next';

import './styles.css';

function HomeDemo() {
  const { t } = useTranslation();

  return (
    <div className='ready'>
      <div className='wrapper'>
        <div className='bind'>
          <div className='title'>{t('InfoDemo.Title')}</div>
          <div className='description'>{t('InfoDemo.Description')}</div>
          <Link to='/demo'>
            <Button cta disabled text={t('InfoDemo.Button')} />
          </Link>
          <em>
            {t('InfoDemo.Info.Title')}
            <br />
            {t('InfoDemo.Info.Description')}
            <br />
            <div className='clients'>
              <a href={'https://curalife.rs'} target='_blank' rel='noopener noreferrer'>
                <Button cta text='Curalife' />
              </a>
              <a href={'https://shop.petmagazine.rs'} target='_blank' rel='noopener noreferrer'>
                <Button cta text='PetMagazine Shop' />
              </a>
              <a href={'https://superfaca.rs'} target='_blank' rel='noopener noreferrer'>
                <Button cta text='Sperfaca' />
              </a>
              <a href={'https://svezakomarce.rs'} target='_blank' rel='noopener noreferrer'>
                <Button cta text='Sve za komarce' />
              </a>
              <a href={'https://pcelar-djordjevic.com'} target='_blank' rel='noopener noreferrer'>
                <Button cta text='Pčelar Đorđević' />
              </a>
              <a href={'https://alexonlineshop.rs/'} target='_blank' rel='noopener noreferrer'>
                <Button cta text='AlexOnlineShop' />
              </a>
            </div>
          </em>
        </div>
      </div>
    </div>
  );
}

export default HomeDemo;
