import React from 'react';

import './styles.css';

let Button = (props) => {
  return (
    <div className={['button', props.cta && 'cta', props.status && 'status', props.disabled && 'disabled'].join(' ')}>
      <div>{props.text}</div>
    </div>
  );
};

export default Button;
