import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Header from './Header';

// Style
import style from './HeaderInfo.module.css';

export default function MainNavigation() {
  return (
    <Header>
      <div className={`wrapper ${style.HeaderInfo}`}>
        <div className={style.container}>
          <div className={style.logoFormat}>
            <Link to='/' className={style.logo}>
              <p>pay.mainloop</p>
              <span>beta</span>
            </Link>
          </div>
          <div className={`${style.email} menu`}>
            <a href={'mailto:team@mainloop.rs'} target='_blank' rel='noopener noreferrer' className='hover'>
              team@mainloop.rs
            </a>
          </div>
        </div>
      </div>
    </Header>
  );
}
