import React from 'react';
import { useTranslation } from 'react-i18next';

// Style
import './style.css';

let Contact = () => {
  const { t } = useTranslation();

  return (
    <div className='view' id='contact'>
      <div className='wrapper'>
        <div className='container bind'>
          <div className='box'>
            <div className='main-info'>
              <div className='title'>{t('Contact.Title')}</div>
              <div className='node'>
                <div className='row'>
                  <div className='description'>{t('Contact.EmailTitle')}</div>
                  <a href='mailto:pay@mainloop.rs' target='_blank' rel='noreferrer' className='mail'>
                    {t('Contact.Email')}
                  </a>
                </div>
                <div className='row'>
                  <div className='description'>{t('Contact.PhoneTitle')}</div>
                  <a href='tel:+381666226445' className='mail'>
                    {t('Contact.Phone')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
