import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Link, Element } from 'react-scroll';

// Components
import PageHeader from '@components/UI/PageHeader';
import ListItem from './ListItem';
import Par from './Par';
import StyckyNav from '@components/Shared/Navigation/StickyNavbar';

// Images
import imgQr from '../../../media/inc/example/qr.png';
import imgSlip from '../../../media/inc/example/slip.png';

// Style
import '../style.css';

let Doc = () => {
  const [activeQ, setActiveQ] = useState('');
  const { t } = useTranslation();

  const code = `{
	"account": "100000000002345678",
	"acceptant": "IME 11000 BEOGRAD",
	"amount": "539",
	"currency": "RSD",
	"payer": "Ime Prezime Ulica 21/3, 11000 Beograd",
	"paymentCode": "221",
	"purpose": "Uplata po računu",
	"model": "",
	"callOn": "109"
}`;

  let handleSetActive = (to) => {
    setActiveQ(to);
  };

  // TODO: Sticky overview (left sidebard) or not?
  return (
    <>
      <div className='view info' id='doc'>
        <PageHeader name={t('API.Header.Name')} subname={t('API.Header.SubName')} description={t('API.Header.Description')} />
        <div className='frame has-header'>
          <div className='wrapper'>
            <div className='container'>
              <div className='module overview'>
                <h4>{t('API.Header.Name')}</h4>
                <ul>
                  <li className='qa'>
                    <Link className='hyperlink' to='aboutApi' spy={true} smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                      {t('API.Q1.Title')}
                    </Link>
                  </li>
                  <li className='qa'>
                    <Link className='hyperlink' to='connectingApi' spy={true} smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                      {t('API.Q2.Title')}
                    </Link>
                  </li>
                  <li className='qa'>
                    <Link className='hyperlink' to='ipsQR' spy={true} smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                      {t('API.Q3.Title')}
                    </Link>
                  </li>
                  <li className='qa'>
                    <Link className='hyperlink' to='paymentSlip' spy={true} smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                      {t('API.Q4.Title')}
                    </Link>
                  </li>
                  <li>
                    <Link className='hyperlink' to='req_res' spy={true} smooth={true} offset={-100} duration={500}>
                      {t('API.Q5.Title')}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='module faq'>
                <div className='k'>
                  <Element name='aboutApi' className='qa'>
                    <div className={['node', `${activeQ === 'aboutApi' ? 'active' : ''}`].join(' ')}>
                      <div className='q'>{t('API.Q1.Title')}</div>
                      <div className='a'>
                        <div className='markdown'>
                          <p>{t('API.Q1.Description')}</p>
                          <Par b={t('API.Q1.Version.Key')} textPre={t('API.Q1.Version.Value')} />
                          <Par b={t('API.Q1.Info.Key')} aHref={t('API.Q1.Info.Value')} aText={t('API.Q1.Info.Value')} />
                        </div>
                      </div>
                    </div>
                  </Element>

                  <Element name='connectingApi' className='qa'>
                    <div className={['node', `${activeQ === 'connectingApi' ? 'active' : ''}`].join(' ')}>
                      <div className='q'>{t('API.Q2.Title')}</div>

                      <div>
                        <div className='q'>{t('API.Q2.A1.Title')}</div>
                        <div className='a'>
                          <div className='markdown'>
                            <Par b={t('API.Q2.A1.Path.Key')} br={true} textPre={t('API.Q2.A1.Path.PreValue')} text={t('API.Q2.A1.Path.Value')} />
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className='q'>{t('API.Q2.A2.Title')}</div>
                        <div className='a'>
                          <div className='markdown'>
                            <Par b='x-api-key' em={t('API.Q2.A2.Header.Em')} emColor='red' br={true} text={t('API.Q2.A2.Header.Value')} />
                            <Par b='Content Type:' text='application/json' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Element>

                  <Element name='ipsQR' className='qa'>
                    <div className={['node', `${activeQ === 'ipsQR' ? 'active' : ''}`].join(' ')}>
                      <div className='q'>{t('API.Q3.Title')}</div>
                      <div className='a'>
                        <div className='markdown'>
                          <Par b={t('API.Q3.Method.Key')} textPre={t('API.Q3.Method.Value')} />
                          <Par b={t('API.Q3.Route.Key')} textPre={t('API.Q3.Route.Value')} />
                          <Par textPre={t('API.Parameters.Title')} em={t('API.Parameters.Em')} emColor='gray' />
                          <ul>
                            <ListItem title={t('API.Parameters.List.Item0.Key')} type='string' description={t('API.Parameters.List.Item0.Value')} />
                            <ListItem title={t('API.Parameters.List.Item1.Key')} type='string' description={t('API.Parameters.List.Item1.Value')} />
                            <ListItem title={t('API.Parameters.List.Item2.Key')} type='string' description={t('API.Parameters.List.Item2.Value')} />
                            <ListItem title={t('API.Parameters.List.Item3.Key')} type='string' description={t('API.Parameters.List.Item3.Value')} />
                            <ListItem title={t('API.Parameters.List.Item4.Key')} type='string' description={t('API.Parameters.List.Item4.Value')} />
                            <ListItem title={t('API.Parameters.List.Item5.Key')} type='string' description={t('API.Parameters.List.Item5.Value')} />
                            <ListItem title={t('API.Parameters.List.Item6.Key')} type='string' description={t('API.Parameters.List.Item6.Value')} />
                            <ListItem title={t('API.Parameters.List.Item7.Key')} type='string' description={t('API.Parameters.List.Item7.Value')} />
                            <ListItem title={t('API.Parameters.List.Item8.Key')} type='string' description={t('API.Parameters.List.Item8.Value')} />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Element>

                  <Element name='paymentSlip' className={['qa', `${activeQ === 'paymentSlip' ? 'active' : ''}`].join(' ')}>
                    <div className='q'>{t('API.Q4.Title')}</div>
                    <div className='a'>
                      <div className='markdown'>
                        <Par b={t('API.Q4.Method.Key')} textPre={t('API.Q4.Method.Value')} />
                        <Par b={t('API.Q4.Route.Key')} textPre={t('API.Q4.Route.Value')} />
                        <Par textPre={t('API.Parameters.Title')} em={t('API.Parameters.Em')} emColor='gray' />
                        <ul>
                          <ListItem title={t('API.Parameters.List.Item0.Key')} type='string' description={t('API.Parameters.List.Item0.Value')} />
                          <ListItem title={t('API.Parameters.List.Item1.Key')} type='string' description={t('API.Parameters.List.Item1.Value')} />
                          <ListItem title={t('API.Parameters.List.Item2.Key')} type='string' description={t('API.Parameters.List.Item2.Value')} />
                          <ListItem title={t('API.Parameters.List.Item3.Key')} type='string' description={t('API.Parameters.List.Item3.Value')} />
                          <ListItem title={t('API.Parameters.List.Item4.Key')} type='string' description={t('API.Parameters.List.Item4.Value')} />
                          <ListItem title={t('API.Parameters.List.Item5.Key')} type='string' description={t('API.Parameters.List.Item5.Value')} />
                          <ListItem title={t('API.Parameters.List.Item6.Key')} type='string' description={t('API.Parameters.List.Item6.Value')} />
                          <ListItem title={t('API.Parameters.List.Item7.Key')} type='string' description={t('API.Parameters.List.Item7.Value')} />
                          <ListItem title={t('API.Parameters.List.Item8.Key')} type='string' description={t('API.Parameters.List.Item8.Value')} />
                        </ul>
                      </div>
                    </div>
                  </Element>

                  <Element name='req_res' className='qa'>
                    {/* <div id='post-1' className='qa'> */}
                    <div className='q'>{t('API.Q5.Title')}</div>
                    <div className='a'>
                      <div className='markdown'>
                        <Par b={t('API.Q5.Type.Key')} textPre={t('API.Q5.Type.Value')} />
                        <Par b={t('API.Q5.Response.Key')} aHref={t('API.Q5.Response.Value')} aText={t('API.Q5.Response.Value')} />
                        <Par b={t('API.Q5.Warning.Key')} />
                        <Par em={t('API.Q5.Warning.Value')} emColor='red' />

                        <Par b={t('API.Q5.Code.Key')} />
                        <div className='codeJson'>
                          <SyntaxHighlighter language='json' style={atomOneDark}>
                            {code}
                          </SyntaxHighlighter>
                        </div>

                        <Par b={t('API.Q5.Images.IPS')} />
                        <img src={imgQr} alt={t('API.Q5.Images.IPS')} className='qr' />

                        <Par b={t('API.Q5.Images.Slip')} />
                        <img src={imgSlip} alt={t('API.Q5.Images.Slip')} />
                      </div>
                    </div>
                    {/* </div> */}
                  </Element>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StyckyNav />
    </>
  );
};

export default Doc;
