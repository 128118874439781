import React from 'react';

import { Link } from 'react-router-dom';
import Button from '@components/UI/Button';

import { useTranslation } from 'react-i18next';

import { ReactComponent as Diagram } from '../../../../../media/inc/diagram.svg';
import './styles.css';

function Onboarding() {
  const { t } = useTranslation();

  return (
    <div className='onboarding'>
      <div className='wrapper'>
        <div className='container bind'>
          <Diagram />
          <div className='intro'>
            <div className='hotline'>
              <div className='name'>{t('Hero.Title')}</div>
              <div className='description'>{t('Hero.Description')}</div>
            </div>
            <Link to='/info'>
              <Button cta text={t('Hero.Button')} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
