import React from 'react';

let ListItem = (props) => {
  return (
    <div>
      <li>
        <p>
          <b>{props.title}</b>
          {props.type && <em className='gray'>{props.type}</em>}
          <br />
          {props.description}
        </p>
      </li>
    </div>
  );
};

export default ListItem;
