import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, Element } from 'react-scroll';

// Components
import PageHeader from '@components/UI/PageHeader';
import ListItem from './ListItem';
import Par from './Par';
import Tip from '@components/UI/Tip';
import StyckyNav from '@components/Shared/Navigation/StickyNavbar';

// import imgCheckout from './static/images/wp/checkout.png';
// import imgTyPage from './static/images/wp/typage.png';
// import imgTyPageIps from './static/images/wp/typageips.png';

// Style
import '../style.css';

let Doc = () => {
  const [activeQ, setActiveQ] = useState('');
  const { t } = useTranslation();

  let handleSetActive = (to) => {
    setActiveQ(to);
  };

  // TODO: Sticky overview (left sidebard) or not?
  return (
    <>
      <div className='view info' id='doc'>
        <PageHeader name={t('Wordpress.Header.Name')} subname={t('Wordpress.Header.SubName')} description={t('Wordpress.Header.Description')} />

        <Tip link={{ text: t('Wordpress.Status.Button'), url: 'https://teitqw.am.files.1drv.com/y4mRStMV-wsr03sBAg5BhVTmq7gffoumQGefQzSmNrAp0Q-drxZcNpqDOuMu67eaFBIKYqmcArZ7ezAR5khPFg2mr_Hu-mzZl723cAA4SgNg_1P9XamZCY9kAwwNHyRjKFO9ERQr_6Qu6qxyAUk_wIKwRFlsWPhmUAeV-JkHZEb1s9PwtxXQn2tsY0fjm7Mta2xCItnr9ax7SvAU3CC53Kb5g' }} title={t('Wordpress.Status.Title')} description={t('Wordpress.Status.Description')} />

        <div className='frame'>
          <div className='wrapper'>
            <div className='container'>
              <div className='module overview'>
                <h4>{t('Wordpress.Header.Name')}</h4>
                <ul>
                  <li className='qa'>
                    <Link className='hyperlink' to='aboutPlugin' spy={true} smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                      {t('Wordpress.Q1.Title')}
                    </Link>
                  </li>
                  <li className='qa'>
                    <Link className='hyperlink' to='pluginSettings' spy={true} smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                      {t('Wordpress.Q2.Title')}
                    </Link>
                  </li>
                  <li className='qa'>
                    <Link className='hyperlink' to='pluginExample' spy={true} smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                      {t('Wordpress.Q3.Title')}
                    </Link>
                  </li>
                  <li className='qa'>
                    <Link className='hyperlink' to='pluginSettingsWoo' spy={true} smooth={true} offset={-100} duration={500} onSetActive={handleSetActive}>
                      {t('Wordpress.Q4.Title')}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className='module faq'>
                <div className='k'>
                  <Element name='aboutPlugin' className='qa'>
                    <div className={['node', `${activeQ === 'aboutPlugin' ? 'active' : ''}`].join(' ')}>
                      <div className='q'>{t('Wordpress.Q1.Title')}</div>
                      <div className='a'>
                        <div className='markdown'>
                          <p>{t('Wordpress.Q1.Description')}</p>
                          <Par b={t('Wordpress.Q1.Version.Key')} textPre={t('Wordpress.Q1.Version.Value')} />
                          {/* Tested Versions */}
                          <Par b={t('Wordpress.Q1.SupportedPHP.Key')} textPre={t('Wordpress.Q1.SupportedPHP.Value')} />
                          <Par b={t('Wordpress.Q1.SupportedWP.Key')} textPre={t('Wordpress.Q1.SupportedWP.Value')} />
                          <Par b={t('Wordpress.Q1.SupportedWoo.Key')} textPre={t('Wordpress.Q1.SupportedWoo.Value')} />
                          <Par b={t('Wordpress.Q1.LastUpdate.Key')} textPre={t('Wordpress.Q1.LastUpdate.Value')} />
                        </div>
                      </div>
                    </div>
                  </Element>
                  <Element name='pluginSettings' className='qa'>
                    <div className={['node', `${activeQ === 'pluginSettings' ? 'active' : ''}`].join(' ')}>
                      <div className='q'>{t('Wordpress.Q2.Title')}</div>
                      <div className='a'>
                        <div className='markdown'>
                          <p>{t('Wordpress.Q2.Description')}</p>
                          <ul>
                            <ListItem title={t('Wordpress.Q2.Item1.Key')} />
                            <ListItem title={t('Wordpress.Q2.Item2.Key')} />
                            <ListItem title={t('Wordpress.Q2.Item3.Key')} />
                            <ListItem title={t('Wordpress.Q2.Item4.Key')} description={t('Wordpress.Q2.Item4.Value')} />
                            <ListItem title={t('Wordpress.Q2.Item5.Key')} />
                            <ListItem title={t('Wordpress.Q2.Item6.Key')} />
                            <ListItem title={t('Wordpress.Q2.Item7.Key')} description={t('Wordpress.Q2.Item7.Value')} />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Element>
                  <Element name='pluginExample' className='qa'>
                    <div className={['node', `${activeQ === 'pluginExample' ? 'active' : ''}`].join(' ')}>
                      <div className='q'>{t('Wordpress.Q3.Title')}</div>
                      <div className='a'>
                        <div className='markdown'>
                          <picture>
                            {/* <source srcSet='/static/images/wp/avif/checkout.avif' type='image/avif' /> */}
                            <source srcSet='/static/images/wp/webp/checkout.webp' type='image/webp' />
                            <img src='/static/images/wp/png/checkout.png' alt='Checkout' />
                          </picture>
                          <picture>
                            {/* <source srcSet='/static/images/wp/avif/typageips.avif' type='image/avif' /> */}
                            <source srcSet='/static/images/wp/webp/typageips.webp' type='image/webp' />
                            <img src='/static/images/wp/png/typageips.png' alt='Ty Page Ips' />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </Element>
                  <Element name='pluginSettingsWoo' className='qa'>
                    <div className={['node', `${activeQ === 'pluginSettingsWoo' ? 'active' : ''}`].join(' ')}>
                      <div className='q'>{t('Wordpress.Q4.Title')}</div>
                      <div className='a'>
                        <div className='markdown'>
                          <picture>
                            {/* <source srcSet='/static/images/wp/avif/mlPaySettings.avif' type='image/avif' /> */}
                            <source srcSet='/static/images/wp/webp/mlPaySettings.webp' type='image/webp' />
                            <img src='/static/images/wp/png/mlPaySettings.png' alt='Checkout' />
                          </picture>
                          <picture>
                            {/* <source srcSet='/static/images/wp/avif/mlPayMethod.avif' type='image/avif' /> */}
                            <source srcSet='/static/images/wp/webp/mlPayMethod.webp' type='image/webp' />
                            <img src='/static/images/wp/png/mlPayMethod.png' alt='Checkout' />
                          </picture>
                          <picture>
                            {/* <source srcSet='/static/images/wp/avif/mlPaySettingsTooltip.avif' type='image/avif' /> */}
                            <source srcSet='/static/images/wp/webp/mlPaySettingsTooltip.webp' type='image/webp' />
                            <img src='/static/images/wp/png/mlPaySettingsTooltip.png' alt='Checkout' />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </Element>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StyckyNav />
    </>
  );
};

export default Doc;
