import React from 'react';

// Style
import './style.css';

let PageHeader = (props) => {
  return (
    <div className='header'>
      <div className='wrapper'>
        <div className='container bind'>
          <div className='page-header'>
            {props.subname ? <div className='sub-name'>{props.subname}</div> : null}
            {props.name ? <div className='name'>{props.name}</div> : null}
            {props.description ? (
              <div className='description'>
                <p>{props.description}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
