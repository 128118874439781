import React from 'react';

let ListItem = (props) => {
  return (
    <p>
      {props.b ? <b>{props.b} </b> : null}
      {props.textPre ? props.textPre : null}
      {props.em ? <em className={props.emColor}>{props.em}</em> : null}
      {props.aText ? <a href={props.aHref}>{props.aText}</a> : null}
      {props.br ? <br /> : null}
      {props.text ? props.text : null}
    </p>
  );
};

export default ListItem;
