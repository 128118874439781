import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@components/UI/Button';

// Style
import './style.css';

let NoMatch = () => {
  const { t } = useTranslation();

  return (
    <div className='view' id='nomatch'>
      <div className='wrapper'>
        <div className='container bind'>
          <div className='box'>
            <div className='main-info'>
              <div className='title'>{t('NoMatch.Title')}</div>
              <p className='description'>{t('NoMatch.Description')}</p>
              <Link to='/contact'>
                <Button text={t('NoMatch.Button')} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
