// Utils
import * as ls from './localStorage';

import Backend from 'i18next-xhr-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

let _defaultLanguage = 'rs';
let _currentLanguage;
let _loadPath = `/static/locales/{{lng}}/{{ns}}.json`;

let getCurrentLanguage = () => {
  if (_currentLanguage) return _currentLanguage;
  _currentLanguage = ls.get('setting.lang');
  return _currentLanguage || _defaultLanguage;
};
let setCurrentLanguage = (lang) => {
  _currentLanguage = lang;
  ls.set('setting.lang', lang);
};

i18n
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    lng: _currentLanguage,
    fallbackLng: _defaultLanguage,
    ns: ['translation'],
    defaultNS: 'translation',
    nsSeparator: false,
    supportedLngs: ['rs', 'en'],

    detection: {
      // order: ['querystring', 'localstorage'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'setting.lang',
    },
    debug: false,
    backend: {
      loadPath: _loadPath,
    },
  });

i18n.getCurrentLanguage = getCurrentLanguage;
i18n.setCurrentLanguage = setCurrentLanguage;
export default i18n;
