import './index.css';
import './index.css';
import '@utils/i18n';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import React, { Suspense } from 'react';

import App from './App';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Suspense fallback='loading'>
    <App />
  </Suspense>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
window.location.hostname === 'localhost' && reportWebVitals(console.log);
