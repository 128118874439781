import React from 'react';
import { Link, Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';

// import Tip from '@components/UI/Tip';

let FAQ = () => {
  const { t } = useTranslation();

  // TODO: Sticky overview (left sidebard) or not?
  return (
    <div className='view info' id='faq'>
      <div className='frame has-header'>
        <div className='wrapper'>
          <div className='container'>
            <div className='module overview'>
              {/* Mainloop Pay */}
              <h4>Mainloop Pay</h4>
              <ul>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-1' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q1.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-2' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q2.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-3' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q3.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-4' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q4.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-5' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q5.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-6' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q6.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-7' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q7.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-8' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q8.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-9' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q9.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-10' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q10.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-11' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q11.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-12' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q12.Title')}
                  </Link>
                </li>
                <li className='qa'>
                  <Link className='hyperlink' activeClass='active' to='post-13' spy={true} smooth={true} offset={-96} duration={500}>
                    {t('FAQ.Q13.Title')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className='module faq'>
              <div className='k'>
                <Element name='post-1' className='qa'>
                  <div className='q'>{t('FAQ.Q1.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q1.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element name='post-2' className='qa'>
                  <div className='q'>{t('FAQ.Q2.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q2.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element name='post-3' className='qa'>
                  <div className='q'>{t('FAQ.Q3.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q3.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element name='post-4' className='qa'>
                  <div className='q'>{t('FAQ.Q4.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q4.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element name='post-5' className='qa'>
                  <div className='q'>{t('FAQ.Q5.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q5.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element name='post-6' className='qa'>
                  <div className='q'>{t('FAQ.Q6.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q6.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element name='post-7' className='qa'>
                  <div className='q'>{t('FAQ.Q7.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q7.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element name='post-8' className='qa'>
                  <div className='q'>{t('FAQ.Q8.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q8.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element name='post-9' className='qa'>
                  <div className='q'>{t('FAQ.Q9.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q9.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element name='post-10' className='qa'>
                  <div className='q'>{t('FAQ.Q10.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q10.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element id='post-11' className='qa'>
                  <div className='q'>{t('FAQ.Q11.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q11.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element id='post-12' className='qa'>
                  <div className='q'>{t('FAQ.Q12.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q12.Description')}</p>
                    </div>
                  </div>
                </Element>
                <Element id='post-13' className='qa'>
                  <div className='q'>{t('FAQ.Q13.Title')}</div>
                  <div className='a'>
                    <div className='markdown'>
                      <p>{t('FAQ.Q13.Description')}</p>
                    </div>
                  </div>
                </Element>
              </div>
            </div>
          </div>
        </div>
        {/* <Tip link={{ text: 'test', link: '/test' }} /> */}
      </div>
    </div>
  );
};

export default FAQ;
