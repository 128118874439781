//* Style
import './App.css';

//* Utils
import * as ls from '@utils/localStorage';

import React, { Suspense, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Contact from '@views/Contact';
import DocApi from '@views/Info/Doc/docApi';
import DocWp from '@views/Info/Doc/docWp';
import Footer from '@components/Shared/Footer/Footer';
import Header from '@components/Shared/Navigation/Header/HeaderInfo';
import Index from '@views/Index';
import InfoPage from '@views/Info';
//* Components
import Loading from '@views/Loading';
import MobileTabs from './components/Shared/Navigation/MobileTabs';
import NavLinks from '@components/Shared/Navigation/NavLinks';
import NoMatch from '@views/NoMatch';
import { useTranslation } from 'react-i18next';

const App = () => {
  const [status, setStatus] = useState({
    code: false,
    detail: false,
  });

  const { t, i18n } = useTranslation();

  //* Loader Manifest
  let timeout = (ms, status) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      code: status,
    }));
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  let setUpManifest = async () => {
    //TODO: Wait translation load...

    await timeout(50);
    await timeout(500, t('Loading.Status.Verifying'));
    if (ls.get('setting.data') === null || !ls.get('setting.data')) {
      const urlGet = new URL(window.location.href);
      const url = urlGet.searchParams.get('lang');

      ls.set('setting.data', true);

      if (url === null || url === 'rs') {
        i18n.changeLanguage('rs');
      }

      await timeout(2200, t('Loading.Status.Downloading'));
      await timeout(600, t('Loading.Status.Storing'));
    }
    await timeout(900, t('Loading.Status.Indexing'));
    setStatus((prevStatus) => ({
      ...prevStatus,
      code: 'ready',
    }));
  };

  //* On Load
  useEffect(() => {
    setUpManifest();
  }, []);

  // <NotificationLink />
  if (status.code !== 'ready') {
    return <Loading status={status} />;
  }

  return (
    <Suspense fallback='loading'>
      <Router>
        <main>
          {/* Header */}
          <Header />
          <NavLinks />
          {/* Pages */}
          <Switch>
            <Route path='/' exact component={Index} />
            <Route path='/info' exact component={InfoPage} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/doc/api' component={DocApi} />
            <Route path='/doc/wordpress' component={DocWp} />
            <Route path='*' component={DocWp}>
              <NoMatch />
            </Route>
          </Switch>
          <MobileTabs />
          <Footer />
        </main>
      </Router>
    </Suspense>
  );
};
export default App;
